import React from 'react'
import {BrowserRouter, Switch, Route} from 'react-router-dom'
import {Navbar} from './components/Navbar'
import {Home} from './pages/Home'
import { About } from './pages/About'
import EditRevision from './components/EditRevision'
import CompareRevision from './components/CompareRevision'



function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <div className="container pt-4">
        <Switch>
          <Route path="/" exact component={Home} />
                  <Route path="/compareRevision" component={CompareRevision} />
                  <Route path="/editRevision" component={EditRevision} />
                  <Route path="/about" component={About} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
