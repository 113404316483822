import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
//import { ItemTypes } from './ItemTypes';
import { useState } from 'react';
//import 'semantic-ui-css/semantic.css';
import { Label} from 'semantic-ui-react'

const style = {
    border: '1px dashed gray',
    padding: '0.5rem 1rem',
    marginBottom: '.5rem',
    backgroundColor: 'white',
    cursor: 'move',
    width:1100
};
export const PersonCard = ({ card, id, index, typeCard, permitedRev, idHeadFamily, idDvor,delPerson, changeHeadPerson, clearHeadPerson, updatePerson, isEditable, connectPerson, deleteConnect }) => {
//export const PersonCard = ({ id, FIO, index, moveCard, typeCard, permitedRev, idHeadFamily, age, liveState, comment, idDvor, agePreviosRevision, relationToHeadFamily, delPerson, changeHeadPerson, clearHeadPerson, updatePerson}) => {

    const ref = useRef(null);
    const [textFIO, setTextFIO] = useState(card.FIO);
    const [textAge, setTextAge] = useState(card.age);
    const [textAgePreiosRevision, setTextAgePreiosRevision] = useState(card.agePreviosRevision);
    const [textRelationToHeadFamily, setTextRelationToHeadFamily] = useState(card.relationToHeadFamily);
    const [textOutPeriod, settextOutPeriod] = useState(card.outPeriod);
    
    const [boolLiveState, setBoolLiveState] = useState(Boolean(parseInt(card.liveState)));
    const [textComment, setTextComment] = useState(card.comment || '');
    const [oldState, setOldState] = useState([{ textFIO: '', textAge: '', boolLiveState: false, textComment: '' }]);
    const [isEdit, setIsEdit] = useState(false);
    const [{ canDrop, isOver, handlerId }, drop] = useDrop({
        accept: permitedRev,//ItemTypes.Rev10,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
            handlerId: monitor.getHandlerId(),
            //return {
            //    handlerId: monitor.getHandlerId(),
            //}
        }),

        drop(item, monitor) {
            //console.log(item);
            //console.log('dropped id '+ item.id);
            //console.log(monitor);
            //console.log('current id ' + id);
            //console.log('head ' + idHeadFamily);
            //console.log(textFIO);
            if (isEditable) {//в режиме редактирования, перетаскиванием, меняется только глава семьи
                //перещаем только если нет уже главы семьи
                //if ((item.idDvor === idDvor) && (idHeadFamily === -1)) {
                if ((idHeadFamily === -1)) {
                    changeHeadPerson(id, item.id, idDvor);
                }
            }
            else {
                //console.log('id ' + id + ' - itemid -' + item.id + ' iddvor ' + idDvor);
                connectPerson(id, item.id);
            }
        }
        ,
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            //console.log('hover');
            //console.log(item.id);
            //console.log(hoverIndex);
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            //moveCard(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        type: typeCard,
        canDrag: !isEdit,
        item: () => {

           // return { id, moveCard, textFIO, index, idDvor };
            return { id, textFIO, index, idDvor };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0 : 1;
    const width = isEditable ? 1100 : 600;
    let backgroundColor = '#EEE';
    let marginLeft = '0rem';
    const isActive = canDrop && isOver;

    if (idHeadFamily !== -1) {
        marginLeft = '1rem';
    }

    if (isActive) {
        backgroundColor = 'darkgreen';
    }
    else if (canDrop) {
        backgroundColor = 'darkkhaki';
    }

    drag(drop(ref));
    
    function handleSaveOldState(e, data) {
        setOldState({ textFIO: textFIO, textAge: textAge, boolLiveState: boolLiveState, textComment: textComment })
        setIsEdit(true);
    }
    function handleDicard(e, data) {
        setOldState({ textFIO: textFIO, textAge: textAge, boolLiveState: boolLiveState, textComment: textComment })
        
        setTextFIO(oldState.textFIO);
        setTextAge(oldState.textAge);
        setBoolLiveState(oldState.boolLiveState);
        setTextComment(oldState.textComment);
        
        setIsEdit(false);
    }
    function handleSave(e, data) {
        setIsEdit(false);
        updatePerson(idDvor, id, textFIO, textAge, boolLiveState, textComment, textRelationToHeadFamily, textAgePreiosRevision, textOutPeriod);

    }
    function handleDeletePerson(e, data) {
        let result = window.confirm('Confirm delete');
        if (result) {
            delPerson(id);
        }
        
    }
    function clearHeadPerson_(e, data) {
        clearHeadPerson(id);
    }

    function handleDeletePersonLink(e, data) {
        
        deleteConnect(card.linkedCount);

    }
    return (<div ref={ref} style={{ ...style, opacity, backgroundColor, marginLeft, width }} data-handler-id={handlerId}>
        {isEditable
            ?
            <table>
                <tbody>
                    <tr>
                        <td>
                            FIO
                        </td>
                        <td>
                            Relation
                        </td>
                        <td>
                            state
                        </td>
                        <td>
                            previos age
                        </td>
                        <td>
                            current age
                        </td>
                        <td>
                            out period
                        </td>
                        <td>
                            comment
                        </td>

                    </tr>
                    <tr>
                        <td>
                            <input disabled={isEdit ? null : "disabled"} className="ui blue input" type="text" value={textFIO} onChange={e => (setTextFIO(e.target.value))} />
                        </td>
                        <td>
                            <input disabled={isEdit ? null : "disabled"} className="ui blue input" type="text" value={textRelationToHeadFamily} onChange={e => (setTextRelationToHeadFamily(e.target.value))} />
                        </td>
                        <td>
                            <input type="checkbox" disabled={isEdit ? null : 'disabled'} key={index} checked={boolLiveState ? 'checked' : null} onChange={e => { setBoolLiveState(!boolLiveState); }} /><Label as='a' color='teal' tag>{boolLiveState ? "Жив" : "Умер"}</Label>
                        </td>
                        <td>
                            <input disabled={isEdit ? null : "disabled"} className="ui blue input" type="number" style={{ width: 60 }} value={textAgePreiosRevision} onChange={e => (setTextAgePreiosRevision(e.target.value))} />
                        </td>
                        <td>
                            <input disabled={isEdit ? null : "disabled"} className="ui blue input" type="number" style={{ width: 60 }} value={textAge} onChange={e => (setTextAge(e.target.value))} />
                        </td>
                        <td>
                            <input disabled={isEdit ? null : "disabled"} className="ui blue input" type="text" value={textOutPeriod} onChange={e => (settextOutPeriod(e.target.value))} />
                        </td>
                        <td>
                            <input disabled={isEdit ? null : "disabled"} className="ui blue input" type="text" value={textComment} onChange={e => (setTextComment(e.target.value))} />
                        </td>

                        <td>
                            {!isEdit
                                ? <>
                                    <button name="edt" className="ui icon button" onClick={handleSaveOldState}>
                                        <i className="edit icon" />
                                    </button>
                                    <button name="deletePerson" className="ui icon button" onClick={handleDeletePerson}>
                                        <i className="delete icon" />
                                    </button>
                                    {idHeadFamily !== -1
                                        ? <button name="edt" className="ui icon button" onClick={clearHeadPerson_}>
                                            <i className="unlink icon" />
                                        </button>
                                        : null
                                    }
                                </>

                                : null}

                            {isEdit
                                ? <button className="ui primary button" onClick={handleSave}>
                                    Save
                                </button>
                                : null}
                            {isEdit
                                ? <button className="ui button" onClick={handleDicard}>
                                    Discard
                                </button>
                                : null}
                        </td>
                    </tr>
                </tbody>
            </table>
            : <div>  {card.isLinked ? <>{card.linkedCount}<i className="arrows alternate horizontal icon" /></> : null}{textFIO}, {textRelationToHeadFamily}, {boolLiveState ? "Жив" : "Умер(ла)"},{textAgePreiosRevision},{textAge},{textOutPeriod},{textComment}
                {card.isLinked ? <button name="edt" className="ui icon button" onClick={handleDeletePersonLink}><i className="unlink icon" /></button> : null}

            </div>
        }
    </div>);
};
