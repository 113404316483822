export const ItemTypes = {
    Rev0: 'rev0',
    Rev1: 'rev1',
    Rev2: 'rev2',
    Rev3: 'rev3',
    Rev4: 'rev4',
    Rev5: 'rev5',
    Rev6: 'rev6',
    Rev7: 'rev7',
    Rev8: 'rev8',
    Rev9: 'rev9',
    Rev10: 'rev10',
    Rev11: 'rev11'
    
    
}
