import React from 'react'

export const Home = () => {

  return (
    <div>
      <h1>Home page</h1>
    </div>
  )
}
