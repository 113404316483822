import React from 'react'

import { useState } from 'react';
import './Dvor.css'
import { ItemTypes } from './ItemTypes'
import { PersonCard } from './PersonCard'
import { Button, Form } from 'semantic-ui-react'

const Dvor = (props) => {

    const [textFIO, setTextFIO] = useState('');
    const [textAge, setTextAge] = useState(-1);
    const [boolLiveState, setBoolLiveState] = useState(false);
    const [textComment, setTextComment] = useState('');
    const [textOutPeriod, setTextOutPeriod] = useState('');
    const [textAgePreviosRevision, setTextAgePreviosRevision] = useState(-1);
    const [textRelationToHeadFamily, setTextRelationToHeadFamily] = useState('');


    function getAcceptedRevision(idRev) {
        //console.log(props.allowAll);
        var a = [];
        var numRev = Object.keys(ItemTypes).length;
        for (let i = 0; i < numRev; i++) {
            if (i !== idRev || props.allowAll) {
                a.push(ItemTypes['Rev' + i.toString()])
            }
        }
        //console.log(a);
        return a;
    };

    //const moveCard = useCallback((dragIndex, hoverIndex) => {
    //    //const dragCard = cards[dragIndex];
    //    //setCards(update(cards, {
    //    //    $splice: [
    //    //        [dragIndex, 1],
    //    //        [hoverIndex, 0, dragCard],
    //    //    ],
    //    //}));
    //    //console.log('tut')
    //    //console.log(dragIndex);
    //    //console.log(hoverIndex);
    //});



    const renderCardP = (card, index, typeRev, permitedRev, idHeadFamily) => {

        return (<PersonCard
            key={index}
            index={index}
            card={card}
            id={card.idPerson}

            /*moveCard={moveCard}*/
            typeCard={typeRev} permitedRev={permitedRev}
            idHeadFamily={idHeadFamily}

            idDvor={props.idDvor}
            agePreviosRevision={card.agePreviosRevision}
                 
            changeHeadPerson={props.changeHeadPerson}
            clearHeadPerson={props.clearHeadPerson}
            updatePerson={props.updatePerson}
            isEditable={props.isEditable}
            connectPerson={props.connectPerson}
            deleteConnect={props.deleteConnect}
            isLinked={card.isLinked}
            linkedCount={card.linkedCount}
        />);
    };
    function handlerAddPerson(e, data) {
        props.onAddPerson(e, data);
        setTextFIO('');
        setTextAge('');
        setBoolLiveState(false);
        setTextComment('');
        setTextAgePreviosRevision('');
        setTextRelationToHeadFamily('');


    }

    return (

        <div className="Dvor" >

            <p>Номер двора: {props.numDvor} </p>

            <div className="DvorItem">
                {/*{console.log(props.pers)}*/}
                {/*{props.pers.map((el, index) => {*/}
                {/*    console.log(el);*/}
                {/*    })*/}
                {/*  }*/}
                {
                    props.pers.map((el, index) => {
                        var permitRev = getAcceptedRevision(props.idRevision);
                        return (
                            el.idPerson === null
                                ? null
                                :
                                    <div className="Card" key={el.idPerson}>
                                    
                                    {
                                        props.filterPersons === '' || el.FIO.toUpperCase().indexOf(props.filterPersons.toUpperCase()) > -1 //ставим фильтр по имени человека
                                        ?renderCardP(el, el.idPerson, ItemTypes['Rev' + props.idRevision.toString()], permitRev, - 1)
                                        :null
                                    }
                                      
                                    

                                    {(el.listFamily.length > 0) &&

                                        el.listFamily.map((person, index) => {
                                            return (
                                                <div className="familyCard" key={person.idPerson}>
                                                    {
                                                        props.filterPersons === '' || person.FIO.toUpperCase().indexOf(props.filterPersons.toUpperCase()) > -1 //ставим фильтр по имени человека
                                                        ? renderCardP(person, person.idPerson, ItemTypes['Rev' + props.idRevision.toString()], permitRev, parseInt(person.headFamily))
                                                            :null}
                                                </div>
                                            )
                                        })
                                    }



                                    </div>
                                  

                        )
                    }
                    )
                }

            </div>
            {props.isEditable
                ?
                < div className="FormEmptyPerson">
                    <Form onSubmit={handlerAddPerson} size='small' >
                        <Form.Group>
                            <Form.Input className="inputIdDvor" type="hidden" value={props.idDvor} />
                            <Form.Input className="inputFIO" type="text" value={textFIO} placeholder='FIO' onChange={e => (setTextFIO(e.target.value))} />
                            <Form.Input className="inputRelationToHeadFamily" type="text" value={textRelationToHeadFamily} placeholder='related to head family' onChange={e => (setTextRelationToHeadFamily(e.target.value))} />
                            <Form.Checkbox style={{ align: 'center' }} className="inputLiveState" label='LiveState' checked={boolLiveState} onChange={e => { setBoolLiveState(!boolLiveState); console.log(boolLiveState); }} />
                            <Form.Input className="inputTextAgePreviosRevision" type="number" style={{ width: 60 }} value={textAgePreviosRevision} placeholder='age to previos revision' onChange={e => (setTextAgePreviosRevision(e.target.value))} />
                            <Form.Input className="inputTextAge" type="number" value={textAge} style={{ width: 60 }} placeholder='age' onChange={e => (setTextAge(e.target.value))} />
                            <Form.Input className="inputTextOutPeriod" type="text" value={textOutPeriod} placeholder='Out Period' onChange={e => (setTextOutPeriod(e.target.value))} />
                            <Form.Input className="inputComment" type="text" value={textComment} placeholder='comment' onChange={e => (setTextComment(e.target.value))} />

                            <Button type='submit'>Add person</Button>
                        </Form.Group>
                    </Form>
                </div>
                : null}
        </div>
    )
}

export default Dvor