import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css';

const options = [
  { key: 1, text: 'Choice 1', value: 1 },
  { key: 2, text: 'Choice 2', value: 2 },
  { key: 3, text: 'Choice 3', value: 3 },
]

const DropList = React.forwardRef((props, ref) => {
	//console.log('dd');
	//console.log(ref);
	return (
		<Dropdown ref={ref}
			clearable
			options={props.options}
			selection
			onChange={props.onChange}
			onAddItem={props.onAddItem}

			search
			scrolling
			{...props.isDisabled ? { disabled: true } : null}
			{...props.allowAdditions ? { allowAdditions: true } : null}
			
			loading={props.loading}
			placeholder={props.text}
			additionLabel={props.additionLabel}

		/>
	)
});

export default DropList