import React from 'react'
import classes from './EditRevision.css'
import { useState,  useEffect } from 'react';
import axios from 'axios'
import DropList from './UI/DropList'
import Dvor from './Dvor'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
//import 'semantic-ui-css/semantic.css';
//import { Base64 } from 'js-base64';


const EditRevision = props => {

    const [listPlaces, setListPlaces] = useState([]);
    const [listRevision, setlistRevision] = useState([]);
    const [isRevisionDisable, setisRevisionDisable] = useState(true);
    const [selectedPlace, setSelectedPlace] = useState('');
    const [selectedRevision, setSelectedRevision] = useState('');
    const [shouldUpdateRevision, setshouldUpdateRevision] = useState(false);
    const [shouldUpdateDvor, setshouldUpdateDvor] = useState(false);
    const [isRevisionListLoading, setisRevisionListLoading] = useState(false);
    const [isLoadingPlaces, setisLoadingPlaces] = useState(true);
    const [numDvorToAdd, setnumDvorToAdd] = useState(1);
    const [shouldUpdatePlace, setshouldUpdatePlace] = useState(false);
    const [textFilterPerson, setTextFilterPerson] = useState('');
    const site_path = 'https://rev.platonov.ws/ap/'
    const user_name_api = 'Api_AP'
    const user_api_password = 'tL5IY92LFA';
    //const auth_header = 'auth: { username: ' + user_name_api + ', password: '+user_api_password+' }';

    const [listDvor, setlistDvor] = useState([]);
    const [isListDvorLoading, setisListDvorLoading] = useState(true);
    const refListRevision = React.createRef();
    const refListPlaces = React.createRef();

    //const [isLoadingRevision, setisLoadingRevision] = useState([false]);
    //перегрузка списка мест
    useEffect(() => {
        async function fetchMyAPI() {
            //console.log(Base64.encode('Api_AP:tL5IY92LFA'));

            let response = axios.get(site_path + '?apitest.getPlaces={}', {
                auth: {
                    username: 'Api_AP',
                    password: 'tL5IY92LFA'
                }
                /*,                headers: {               Authorization: 'Basic ' + Base64.encode('Api_AP:tL5IY92LFA') }*/
            });

            const places = [];
            response = await response;
            // dataSet(response);
            //console.log(response);
            Object.keys(response.data.response.places).forEach((key) => {
                //console.log('' + key + ' ' + response.data.response.places[key]);
                places.push({
                    key: key,
                    value: key,
                    text: response.data.response.places[key]
                }
                );
            })
            setListPlaces(places);
            setisLoadingPlaces(false);
            //console.log(places);
            //this.setState({ places, isLoadingPlaces: false });
        }

        fetchMyAPI()
    }, [shouldUpdatePlace])
    //загрузка ревизий
    useEffect(() => {
        async function fetchMyAPI() {
            let response = axios.get(site_path + '?apitest.getRevisions={"Place":"' + selectedPlace + '"}');
            const revisions = [];
            response = await response;
            // dataSet(response);
            //console.log(response.data.response.Revisions);
            Object.keys(response.data.response.Revisions).forEach((key) => {

                revisions.push({
                    key: response.data.response.Revisions[key].ID,
                    value: key,
                    text: response.data.response.Revisions[key].Year
                }
                );
            })
            //console.log(revisions);
            setlistRevision(revisions);

            setisRevisionListLoading(false);
            setisRevisionDisable(false);

        }

        //console.log('-1');
        fetchMyAPI()
    }, [shouldUpdateRevision, selectedPlace])

    //грузим текущее состояние по ревизии
    useEffect(() => {
        async function fetchMyAPI() {

            const url = site_path + '?apitest.getDvor={"Place":"' + selectedPlace + '","Year":"' + selectedRevision + '"}';
            //console.log(url);
            const response = await axios.get(url);
            //console.log(response);

            let lDvor = response.data.response.listDvor;

            setlistDvor(lDvor);
            //console.log(listDvor);
            setisListDvorLoading(false);
        }


        if (selectedRevision === '') {
            setlistDvor([]);
        }
        else {
            fetchMyAPI();
        }


    }, [shouldUpdateDvor, selectedRevision, selectedPlace])




    function handleChangePlaceList(e, data) {
        //console.log(data);
        //setisRevisionDisable(data.value == "")
        if (data.value !== "") {
            setlistRevision([]);
            setisRevisionListLoading(true);
            //console.log(data.options[data.value].text);
            if (typeof data.options[data.value] !== 'undefined') {
                setSelectedPlace(data.options[data.value].text);
            }

        }
        else {
            setlistRevision([]);
            setisRevisionListLoading(false);
            setisRevisionDisable(true);
        }

    }
    //добавление ревизии
    async function handleAddition(e, value) {
        setlistRevision([]);
        setisRevisionDisable(true);
        //console.log(ref);
        refListRevision.current.clearValue(e)
        let response = await axios.get(site_path + '?apitest.addRevision={"Place":"' + selectedPlace + '","Year":"' + value.value + '"}');
        //TODO обработка ошибок
        setshouldUpdateRevision(!shouldUpdateRevision);

    }
    //добавление мест
    async function handleAdditionPlace(e, value) {
        //console.log(value.value);
        setListPlaces([]);
        setisLoadingPlaces(true);
        //console.log(ref);
        refListPlaces.current.clearValue(e);
        let response = await axios.get(site_path + '?apitest.addPlace={"Place":"' + value.value + '"}');
        //console.log(response);
        //TODO обработка ошибок
        setshouldUpdatePlace(!shouldUpdatePlace);
    }
    //выбор ревизии
    function handleChangeRevision(e, data) {

        //console.log(data);
        //setSelectedPlace('');
        if (data.value !== '') {
            if (typeof data.options[data.value] !== 'undefined') {
                setSelectedRevision(data.options[data.value].text);
            }
        }
        else {
            setisListDvorLoading(true);
            setSelectedRevision('');
        }

    }

    async function handleAddDvor(e, data) {
        let idRevision = 0;
        listRevision.forEach(key => {
            if (key.text === selectedRevision) {
                idRevision = key.key;

            }
        }
        )

        //setSelectedPlace('');
        //setisListDvorLoading(true);
        let response = await axios.get(site_path + '?apitest.addDvor={"idRevision":"' + idRevision + '","numDvorToAdd":"' + numDvorToAdd + '"}');
        //TODO обработка ошибок
        setshouldUpdateDvor(!shouldUpdateDvor);


    }
    async function handlerAddPerson(e, data) {
        let idDvor = data.children.props.children[0].props.value;
        let FIO = data.children.props.children[1].props.value;
        let relatedToHeadFamily = data.children.props.children[2].props.value;
        let liveState = data.children.props.children[3].props.checked;
        let agePreviosRevission = data.children.props.children[4].props.value;
        let age = data.children.props.children[5].props.value;
        let outPeriod = data.children.props.children[6].props.value;
        let comment = data.children.props.children[7].props.value;
        //console.log(data.children.props);
        let response = await axios.get(site_path + '?apitest.addPerson={"idDvor":"' + idDvor + '","FIO":"' + FIO + '","age":"' + age + '","liveState":"' + liveState + '","relatedToHeadFamily":"' + relatedToHeadFamily + '","outPeriod":"' + outPeriod + '","agePreviosRevision":"' + agePreviosRevission + '","comment":"' + comment + '"}');
        console.log(response);
        //TODO обработка ошибок
        setshouldUpdateDvor(!shouldUpdateDvor);
    }

    async function handlerChangeHeadPerson(idHead, idDrop, idDvor) {

        //console.log(idHead, idDrop);
        //console.log(listDvor);
        if (idHead !== idDrop) {
            let response = await axios.get(site_path + '?apitest.changeHeadPerson={"idHead":"' + idHead + '","idPerson":"' + idDrop + '","idDvor":"' + idDvor + '"}');
            //console.log(response);
            //TODO обработка ошибок
            setshouldUpdateDvor(!shouldUpdateDvor);
        }
    }
    async function handlerUpdatePerson(idDvor, idPerson, FIO, age, liveState, comment, relatedToHeadFamily, agePreviosRevission,outPeriod) {

        //console.log(idHead, idDrop);
        //console.log("out" + outPeriod);

        let response = await axios.get(site_path + '?apitest.updatePerson={"idDvor":"' + idDvor + '","idPerson":"' + idPerson + '","FIO":"' + FIO + '","age":"' + age + '","liveState":"' + liveState + '","relatedToHeadFamily":"' + relatedToHeadFamily + '","agePreviosRevision":"' + agePreviosRevission + '","outPeriod":"' + outPeriod + '","comment":"' + comment + '"}');
          //  console.log(response);
            //TODO обработка ошибок
            setshouldUpdateDvor(!shouldUpdateDvor);
        
    }

    async function handlerClearHeadPerson(idPerson) {
        // console.log("head" + idPerson );
        let response = await axios.get(site_path + '?apitest.clearHeadPerson={"idPerson":"' + idPerson + '"}');
        // console.log("head"+response);
        //TODO обработка ошибок
        setshouldUpdateDvor(!shouldUpdateDvor);
    }

    async function handlerDeletePerson(idPerson) {
        let response = await axios.get(site_path + '?apitest.deletePerson={"idPerson":"' + idPerson + '"}');
        //console.log(response);
        //TODO обработка ошибок
        setshouldUpdateDvor(!shouldUpdateDvor);
    }


    return (
        <DndProvider backend={HTML5Backend}>
            <div className="EditRevision" >
                <h1>EditRevision</h1>
                <DropList ref={refListPlaces}

                    options={listPlaces}
                    isDisabled={isLoadingPlaces}
                    loading={isLoadingPlaces}

                    allowAdditions={true}
                    onAddItem={handleAdditionPlace}
                    onChange={handleChangePlaceList}
                    text="Выберите город"
                />

                <br />
            Ревизии: <DropList ref={refListRevision}
                    options={listRevision}
                    isDisabled={isRevisionDisable}
                    loading={isRevisionListLoading}
                    allowAdditions={true}
                    text="Выберите ревизию"
                    key='RevisionRev1'
                    onAddItem={handleAddition}
                    onChange={handleChangeRevision}
                />
                <br />
                <input className="filterPersons" type="text" onChange={e => (setTextFilterPerson(e.target.value))} />
                <br />
                {isListDvorLoading
                    ? <p className="text-center">Выберите ревизию...</p>
                    : listDvor.map((el, index) => {

                        return (

                            <Dvor
                                key={index}
                                numDvor={el.numDvor}
                                idDvor={el.idDvor}
                                pers={el.Family}

                                idRevision={0}
                                allowAll={true}
                                isEditable={true}
                                filterPersons={textFilterPerson}
                                onAddPerson={handlerAddPerson}
                                deletePerson={handlerDeletePerson}
                                changeHeadPerson={handlerChangeHeadPerson}
                                clearHeadPerson={handlerClearHeadPerson}
                                updatePerson={handlerUpdatePerson}
                                

                            />

                        )

                    })


                }
                {isListDvorLoading
                    ? null
                    : <div>
                        <input className="ui blue input" type="text" value={numDvorToAdd} onChange={e => { setnumDvorToAdd(e.target.value) }} />
                        < button name="AddDvor" className="ui icon button" onClick={handleAddDvor}  >
                            <i class="edit icon" />
                    Добавить двор
                </button>
                    </div>
                }
            </div>
        </DndProvider>


    );



};


export default EditRevision