import React, { Component } from 'react'
import Dvor from './Dvor'
import './CompareRevision.scss'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import axios from 'axios'
import DropList from './UI/DropList'
import { Item } from 'semantic-ui-react'
//import { Ref } from 'react-component-ref'

class CompareRevision extends Component {
    constructor() {
        super();
        this.placeList0 = React.createRef();
        this.placeList1 = React.createRef();
        this.yearList0 = React.createRef();
        this.yearList1 = React.createRef();
        this.filterList = React.createRef();
    }
    site_path = 'https://rev.platonov.ws/ap/';

    state = {
        revisionId: [{
            year: '',
            place: '',
            isPlaceSelected: false,
            isRevisionSelected: false,
            isLoadingRevision: true,
            listDvor:
                [
                ],
            linkedPersons:
                []

        },
        {
            year: '',
            place: '',

            isPlaceSelected: false,
            isRevisionSelected: false,
            isLoadingRevision: true,
            listDvor:
                [],
            linkedPersons:
                []
        }],
        listRevisions: [
            [],
            []
        ],
        pageTitle: "Test 1133",
        isLoadingPlaces: true,
        //revisionsRev0: [],
        //revisionsRev1: [],
        filterPersonRev0: "",
        filterPersonRev1: "",

        places: [],

    }

    clickBtn = (e) => {

        //axios.get('https://platonov.ws/ap/?apitest.getPlaces={}').then(
        //    response => {
        //        console.log(response);

        //    }
        //).catch(error => console.log(error));
        this.yearList0.current.clearValue(e);
        //console.log(this.wrapper.current);

    }

    async componentDidMount() {
        try {
            const response = await axios.get(this.site_path + '?apitest.getPlaces={}');
            const places = [];
            //console.log(response.data);

            Object.keys(response.data.response.places).forEach((key) => {
                // console.log('' + key + ' ' + response.data.response.places[key]);
                places.push({
                    key: key,
                    value: key,
                    text: response.data.response.places[key]
                }
                );
            })
            //console.log(places);
            this.setState({ places, isLoadingPlaces: false });

        }

        catch (e) {
            console.log(e);
        }

    }
    getIdRev(addLbl) {
        if (addLbl === 'Rev0') {
            return 0;
        }
        if (addLbl === 'Rev1') {
            return 1;
        }
    }
    //места
    handleChangePlaceList = async (e, data) => {
        e.preventDefault()

        let revisionId = this.state.revisionId;
        this.clearLinkedPerson();
        const idRev = this.getIdRev(data.additionLabel);


        //блокируем возможность выбора ревизий при пустом селе
        revisionId[idRev].isPlaceSelected = ((!data.value == ""));

        if (revisionId[idRev].isPlaceSelected) {

            revisionId[idRev].place = data.options[data.value].text;

        }
        //так как произошла смена места очистим ревизии
        if (idRev === 0) {

            this.yearList0.current.clearValue(e);
            
        }
        if (idRev === 1) {

            this.yearList1.current.clearValue(e);
            
        }
        revisionId[idRev].isLoadingRevision = true;

        this.setState(revisionId);
        //загружаем ревизии
        if (data.value !== '') {
            try {
                const response = await axios.get(this.site_path + '?apitest.getRevisions={"Place":"' + data.options[data.value].text + '"}');
                //console.log("!!!!");
                //console.log(data.options[data.value].text);
                //console.log(response.data);
                const Revisions = [];
                let listRevisions = this.state.listRevisions;
                //revisionId[idRev].place = data.options[data.value].text;

                Object.keys(response.data.response.Revisions).forEach((key) => {

                    Revisions.push({
                        key: response.data.response.Revisions[key].ID,
                        value: key,
                        text: response.data.response.Revisions[key].Year
                    }
                    );
                })

                listRevisions[idRev] = Revisions;
                revisionId[idRev].isLoadingRevision = false;
                this.setState({ revisionId: revisionId, listRevisions: listRevisions });


            } catch (e) {
                console.log(e)
            }
        }

    }

    checkPair(revisionId, id, id1, linkedCount) {
        let isPairFind = false;
        revisionId[1].listDvor.forEach((itemDvor) => {
            //console.log(itemDvor);
            itemDvor.Family.forEach((itemFamily) => {
                //console.log(itemFamily);
                if (itemFamily.idPerson == id || itemFamily.idPerson == id1) {
                    isPairFind = true;
                    itemFamily.isLinked = true;
                    itemFamily.linkedCount = linkedCount;
                }
                itemFamily.listFamily.forEach((itemListFamily) => {
                    //console.log(itemListFamily);
                    if (itemListFamily.idPerson == id || itemListFamily.idPerson == id1) {
                        isPairFind = true;
                        itemListFamily.isLinked = true;
                        itemListFamily.linkedCount = linkedCount;
                    }
                });
            });
        });
        return isPairFind;
    }
    //очистка отмеченых
    clearLinkedPerson() {
        let revisionId = this.state.revisionId;
        for (let i = 0; i <= 1; i++) {
            revisionId[i].listDvor.forEach((itemDvor) => {

                itemDvor.Family.forEach((itemFamily) => {
                    itemFamily.isLinked = false;

                    itemFamily.listFamily.forEach((itemListFamily) => {

                        itemListFamily.isLinked = false;

                    });
                });
            });
        }
        this.setState(revisionId);
    }
    //-----------------поставм отметки уже связаным людям
    markPerson(revisionId) {

        if ((this.yearList0.current.state.selectedIndex !== undefined && this.yearList1.current.state.selectedIndex !== undefined) &&
            (this.yearList0.current.state.selectedIndex != 0 && this.yearList1.current.state.selectedIndex != 0)) {
            //console.log(revisionId[0]);
            //console.log(revisionId[1]);
            let linkedCount = 1;
            revisionId[0].linkedPersons.forEach((itemPair) => {
                //console.log(itemPair);
                revisionId[0].listDvor.forEach((itemDvor) => {
                    //console.log(itemDvor);
                    itemDvor.Family.forEach((itemFamily) => {
                        //console.log(itemFamily);
                        if (itemFamily.idPerson == itemPair.idPerson || itemFamily.idPerson == itemPair.idLinkedPerson) {
                            
                            if (this.checkPair(revisionId, itemPair.idPerson, itemPair.idLinkedPerson, linkedCount)) {
                                itemFamily.isLinked = true;
                                itemFamily.linkedCount = linkedCount;
                                linkedCount = linkedCount + 1;
                            }
                        }
                        itemFamily.listFamily.forEach((itemListFamily) => {
                            //console.log(itemListFamily);
                            if (itemListFamily.idPerson == itemPair.idPerson || itemListFamily.idPerson == itemPair.idLinkedPerson) {
                                //console.log("go");
                                if (this.checkPair(revisionId, itemPair.idPerson, itemPair.idLinkedPerson, linkedCount)) {
                                    itemListFamily.isLinked = true;
                                    itemListFamily.linkedCount = linkedCount;
                                    linkedCount = linkedCount + 1;
                                }
                            }
                        });
                    });
                });
            });
           
            this.setState(revisionId);
        }
        else {//очистим ометки
            this.clearLinkedPerson();
        }
    }
    //-----------------изменние ревизии
    handleChangeRevisionList = async (e, data) => {
        try {
            // console.log(this.placeList0.current);
            // console.log(e);
            //console.log(data);
            const idRev = this.getIdRev(data.additionLabel)
            let revisionId = this.state.revisionId;
            //очистим данные
            this.clearLinkedPerson();
            revisionId[idRev].listDvor = [];
            revisionId[idRev].linkedPersons = [];
            //console.log(this.placeList0.current.state.__options[this.placeList0.current.state.value]);
            //this.state.revisionId[0].year
            revisionId[idRev].isRevisionSelected = ((!data.value === ""));
            if (revisionId[idRev].isPlaceSelected) {
                revisionId[idRev].year = data.value;
            }

            if (data.value !== "") {
                //const url = 'https://platonov.ws/ap/?apitest.getDvor={"Place":"' + this.placeList0.current.state.__options[this.placeList0.current.state.value].text + '","Year":"' + data.options[data.value].text + '"}';
                const url = this.site_path + '?apitest.getDvor={"Place":"' + revisionId[idRev].place + '","Year":"' + data.options[data.value].text + '"}';
                //console.log('URL   '+url);

                let response = await axios.get(url);
                //console.log(response);

                revisionId[idRev].listDvor = response.data.response.listDvor;
                revisionId[idRev].isRevisionSelected = true;
                //console.log(revisionId);
                const urlLinkedPerson = this.site_path + '?apitest.getLinkedPerson={"idRevision":"' + data.options[data.value].key + '"}';

                //console.log('URL   '+url);
                response = await axios.get(urlLinkedPerson);
                revisionId[idRev].linkedPersons = response.data.response.linkedPerson;
                //console.log(response);

                this.setState(revisionId);
                //console.log(revisionId);


                this.markPerson(revisionId);


            } else {


                revisionId[idRev].isRevisionSelected = false;
                this.setState(revisionId);


            }
            //console.log(this.state.revisionId[0].Family);
            //console.log(data.options[data.value].text);
            //console.log(.response.data);
        } catch (e) {
            console.log(e);
        }
    }
    connectPerson = async (id, idLinked) => {
        //console.log("connect " + id + " " + idLinked);
        const response = await axios.get(this.site_path + '?apitest.connectPeople={"id":' + id + ',"idLinked":' + idLinked + '}');
        if (response.data.response.result === "Ok") {
            let revisionId = this.state.revisionId;
            revisionId[0].linkedPersons.push({ 'idPerson': id, 'idLinkedPerson': idLinked });
            revisionId[1].linkedPersons.push({ 'idPerson': id, 'idLinkedPerson': idLinked });
            //console.log(revisionId[0].linkedPersons);
            this.markPerson(revisionId);
            //this.setState(revisionId);
        }
       // console.log(response);
        //TODO обработка ошибок
    }
    //удаление связи
    deleteConnect = async (idCount) => {
        let revisionId = this.state.revisionId;
        const response = await axios.get(this.site_path + '?apitest.deleteConnect={"id":' + revisionId[0].linkedPersons[idCount - 1].idPerson + ',"idLinked":' + revisionId[0].linkedPersons[idCount - 1].idLinkedPerson + '}');
        //console.log(response);
        console.log(revisionId[0].linkedPersons);
        console.log(revisionId[0].linkedPersons[idCount - 1]);
        if (response.data.response.result === "Ok") {
            
            //console.log(revisionId[0].linkedPersons[idCount - 1]);
            revisionId[0].linkedPersons.splice(idCount - 1,1);
            console.log(revisionId[0].linkedPersons);
            //console.log(revisionId[0].linkedPersons);
            this.clearLinkedPerson();
            this.markPerson(revisionId);
        }

    }

    render() {

        return (
            <DndProvider backend={HTML5Backend}>
                <div className="CompareRevision">

                    <div className="Revision">
                        Населеные пункты:

                        <DropList

                            options={this.state.places}
                            onChange={this.handleChangePlaceList}
                            additionLabel='Rev0'
                            isDisabled={this.state.isLoadingPlaces}
                            loading={this.state.isLoadingPlaces}
                            text="Выберите город"
                            key='PlaceRev0'
                            ref={this.placeList0}
                        />

                        <br />

                        Ревизии: <DropList

                            options={this.state.listRevisions[0]}
                            onChange={this.handleChangeRevisionList}
                            additionLabel='Rev0'
                            isDisabled={this.state.revisionId[0].isLoadingRevision}
                            loading={this.state.revisionId[0].isLoadingRevision}
                            text="Выберите ревизию"
                            key='RevisionRev0'
                            ref={this.yearList0}

                        />

                        <hr />

                       
                        
                        <div >

                            {
                                this.state.revisionId[0].isRevisionSelected
                                    ?
                                    <div>
                                   
                                        <input className="filterPersonRev0" type="text" onChange={e => { this.setState({ 'filterPersonRev0': e.target.value, 'filterPersonRev1': e.target.value }); console.log(this.filterList); }} />
                                    <br />
                                        {this.state.revisionId[0].listDvor.map((el, index) => {
                                            //{
                                            //    console.log(el);
                                            //    console.log(Array.isArray(el.Family));
                                            //}
                                            return (

                                                <Dvor
                                                    key={index}
                                                    numDvor={el.numDvor}
                                                    idDvor={el.idDvor}
                                                    pers={el.Family}
                                                    isEditable={false}
                                                    idRevision={0}
                                                    filterPersons={this.state.filterPersonRev0}
                                                    allowAll={false}
                                                    connectPerson={this.connectPerson}
                                                    deleteConnect={this.deleteConnect}


                                                />

                                            )

                                        }
                                        )}
                                        </div>
                                    : null
                            }

                        </div>

                    </div>


                    <div className="Revision">
                        Населеные пункты: <DropList
                            options={this.state.places}
                            onChange={this.handleChangePlaceList}
                            additionLabel="Rev1"
                            loading={this.state.isLoadingPlaces}
                            text="Выберите город"
                            key='ssss1'
                            ref={this.placeList1}
                        />

                        <br />
                        Ревизии:
                        <DropList

                            options={this.state.listRevisions[1]}
                            onChange={this.handleChangeRevisionList}
                            additionLabel='Rev1'
                            isDisabled={this.state.revisionId[1].isLoadingRevision}
                            loading={this.state.revisionId[1].isLoadingRevision}
                            text="Выберите ревизию"
                            key='RevisionRev1'
                            ref={this.yearList1}
                        />

                        <hr />

                        
                        <div >

                            {
                                
                                  this.state.revisionId[1].isRevisionSelected
                                    ? <div>
                                        
                                        <input className="filterPersonRev1" type="text" value={this.state.filterPersonRev1} ref={this.filterList} onChange={e => (this.setState({ 'filterPersonRev1': e.target.value }))} />
                                        <br />
                                        {this.state.revisionId[1].listDvor.map((el, index) => {
                                                //{
                                                //console.log(el);
                                                //console.log(Array.isArray(el.Family));
                                                //}
                                                return (

                                                    <Dvor
                                                        key={index}
                                                        numDvor={el.numDvor}
                                                        pers={el.Family}
                                                        idRevision={1}
                                                        allowAll={false}
                                                        filterPersons={this.state.filterPersonRev1}
                                                        connectPerson={this.connectPerson}
                                                        deleteConnect={this.deleteConnect}
                                                    />

                                                )

                                        }
                                        )
                                        }
                                        </div>
                                        
                                        
                                    :null
                            }

                        </div>

                    </div>

                </div>
            </DndProvider>

        )
    }
}

export default CompareRevision